export const getAddEthereumChain = (testnet, chainId) => {
  let o = allchains.reduce(
    (obj, item) => Object.assign(obj, { [item.chainId]: item }),
    {}
  );
  return o;
};

export const allchains = [
  {
    chainName: "Moonbeam Testnet",
    rpcUrls: ["https://rpc.api.moonbase.moonbeam.network"],
    chainId: 1284,
  },
  {
    chainName: "Moonbeam Mainnet",
    rpcUrls: ["https://rpc.api.moonbeam.network"],
    chainId: 1287,
  },
  {
    chainName: "Abeychain Mainnet",
    rpcUrls: ["https://rpc.abeychain.com"],
    chainId: 0xb3,
  },
  {
    chainName: "Abeychain Testnet",
    rpcUrls: ["https://testrpc.abeychain.com"],
    chainId: 0xb2,
  },
  {
    name: "Ethereum Mainnet",
    chain: "ETH",
    network: "mainnet",
    icon: "ethereum",
    rpcUrls: [
      "https://mainnet.infura.io/v3/${INFURA_API_KEY}",
      "wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}",
      "https://api.mycryptoapi.com/eth",
      "https://cloudflare-eth.com",
    ],
    faucets: [],
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    infoURL: "https://ethereum.org",
    shortName: "eth",
    chainId: 1,
    networkId: 1,
    slip44: 60,
    ens: {
      registry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
    },
    explorers: [
      {
        name: "etherscan",
        url: "https://etherscan.io",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Ethereum Testnet Ropsten",
    chain: "ETH",
    network: "ropsten",
    rpcUrls: [
      "https://ropsten.infura.io/v3/${INFURA_API_KEY}",
      "wss://ropsten.infura.io/ws/v3/${INFURA_API_KEY}",
    ],
    faucets: ["https://faucet.ropsten.be?${ADDRESS}"],
    nativeCurrency: {
      name: "Ropsten Ether",
      symbol: "ROP",
      decimals: 18,
    },
    infoURL: "https://github.com/ethereum/ropsten",
    shortName: "rop",
    chainId: 3,
    networkId: 3,
    ens: {
      registry: "0x112234455c3a32fd11230c42e7bccd4a84e02010",
    },
  },
  {
    name: "Ethereum Testnet Rinkeby",
    chain: "ETH",
    network: "rinkeby",
    rpcUrls: [
      "https://rinkeby.infura.io/v3/${INFURA_API_KEY}",
      "wss://rinkeby.infura.io/ws/v3/${INFURA_API_KEY}",
    ],
    faucets: ["https://faucet.rinkeby.io"],
    nativeCurrency: {
      name: "Rinkeby Ether",
      symbol: "RIN",
      decimals: 18,
    },
    infoURL: "https://www.rinkeby.io",
    shortName: "rin",
    chainId: 4,
    networkId: 4,
    ens: {
      registry: "0xe7410170f87102df0055eb195163a03b7f2bff4a",
    },
    explorers: [
      {
        name: "etherscan-rinkeby",
        url: "https://rinkeby.etherscan.io",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "GateChain Testnet",
    chainId: 85,
    shortName: "gttest",
    chain: "GT",
    network: "testnet",
    networkId: 85,
    nativeCurrency: {
      name: "GateToken",
      symbol: "GT",
      decimals: 18,
    },
    rpcUrls: ["https://meteora-evm.gatenode.cc"],
    faucets: ["https://www.gatescan.org/testnet/faucet"],
    explorers: [
      {
        name: "GateScan",
        url: "https://www.gatescan.org/testnet",
        standard: "EIP3091",
      },
    ],
    infoURL: "https://www.gatechain.io",
  },
  {
    name: "GateChain Mainnet",
    chainId: 86,
    shortName: "gt",
    chain: "GT",
    network: "mainnet",
    networkId: 86,
    nativeCurrency: {
      name: "GateToken",
      symbol: "GT",
      decimals: 18,
    },
    rpcUrls: ["https://evm.gatenode.cc"],
    faucets: ["https://www.gatescan.org/faucet"],
    explorers: [
      {
        name: "GateScan",
        url: "https://www.gatescan.org",
        standard: "EIP3091",
      },
    ],
    infoURL: "https://www.gatechain.io",
  },

  {
    name: "BSC Mainnet",
    chain: "BSC",
    network: "Chapel",
    rpcUrls: [
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed.binance.org",
    ],
    faucets: ["https://testnet.binance.org/faucet-smart"],
    nativeCurrency: {
      name: "Binance Chain Token",
      symbol: "BNB",
      decimals: 18,
    },
    chainId: 56,
  },

  {
    name: "Binance Smart Chain Testnet",
    chain: "BSC",
    network: "Chapel",
    rpcUrls: [
      "https://data-seed-prebsc-1-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-1-s2.binance.org:8545",
      "https://data-seed-prebsc-2-s2.binance.org:8545",
      "https://data-seed-prebsc-1-s3.binance.org:8545",
      "https://data-seed-prebsc-2-s3.binance.org:8545",
    ],
    faucets: ["https://testnet.binance.org/faucet-smart"],
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "tBNB",
      decimals: 18,
    },
    infoURL: "https://testnet.binance.org/",
    shortName: "bnbt",
    chainId: 97,
    networkId: 97,
    explorers: [
      {
        name: "bscscan-testnet",
        url: "https://testnet.bscscan.com",
        standard: "EIP3091",
      },
    ],
  },

  {
    name: "xDAI Chain",
    chain: "XDAI",
    network: "mainnet",
    rpcUrls: [
      "https://rpc.xdaichain.com",
      "https://xdai.poanetwork.dev",
      "wss://rpc.xdaichain.com/wss",
      "wss://xdai.poanetwork.dev/wss",
      "http://xdai.poanetwork.dev",
      "https://dai.poa.network",
      "ws://xdai.poanetwork.dev:8546",
    ],
    faucets: [
      "https://free-online-app.com/faucet-for-eth-evm-chains/",
      "https://xdai-app.herokuapp.com/faucet",
    ],
    nativeCurrency: {
      name: "xDAI",
      symbol: "xDAI",
      decimals: 18,
    },
    infoURL: "https://forum.poa.network/c/xdai-chain",
    shortName: "xdai",
    chainId: 100,
    networkId: 100,
    slip44: 700,
    explorers: [
      {
        name: "blockscout",
        url: "https://blockscout.com/poa/xdai",
        standard: "EIP3091",
      },
    ],
  },

  {
    name: "Velas EVM Mainnet",
    chain: "Velas",
    network: "mainnet",
    icon: "velas",
    rpcUrls: [
      "https://evmexplorer.velas.com/rpc",
      "https://explorer.velas.com/rpc",
    ],
    faucets: [],
    nativeCurrency: {
      name: "Velas",
      symbol: "VLX",
      decimals: 18,
    },
    infoURL: "https://velas.com",
    shortName: "vlx",
    chainId: 106,
    networkId: 106,
    explorers: [
      {
        name: "Velas Explorer",
        url: "https://evmexplorer.velas.com",
        standard: "EIP3091",
      },
    ],
  },

  {
    name: "Velas EVM Testnet",
    chain: "Velas",
    network: "mainnet",
    icon: "velas",
    rpcUrls: ["https://explorer.testnet.velas.com/rpc"],
    faucets: [],
    nativeCurrency: {
      name: "Velas",
      symbol: "VLX",
      decimals: 18,
    },
    infoURL: "https://velas.com",
    shortName: "vlx",
    chainId: 0x6f,
    networkId: 106,
    explorers: [
      {
        name: "Velas Explorer",
        url: "https://evmexplorer.velas.com",
        standard: "EIP3091",
      },
    ],
  },

  //0x6f

  {
    name: "Fuse Mainnet",
    chain: "FUSE",
    network: "mainnet",
    rpcUrls: ["https://rpc.fuse.io"],
    faucets: [],
    nativeCurrency: {
      name: "Fuse",
      symbol: "FUSE",
      decimals: 18,
    },
    infoURL: "https://fuse.io/",
    shortName: "fuse",
    chainId: 122,
    networkId: 122,
  },

  {
    name: "Polygon Mainnet",
    chain: "Polygon",
    network: "mainnet",
    rpcUrls: [
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.matic.network",
      "https://matic-mainnet.chainstacklabs.com",
      "https://rpc-mainnet.maticvigil.com",
      "https://rpc-mainnet.matic.quiknode.pro",
      "https://matic-mainnet-full-rpc.bwarelabs.com",
    ],
    faucets: [],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    infoURL: "https://polygon.technology/",
    shortName: "MATIC",
    chainId: 137,
    networkId: 137,
    slip44: 966,
    explorers: [
      {
        name: "polygonscan",
        url: "https://polygonscan.com/",
        standard: "EIP3091",
      },
    ],
  },

  {
    name: "Fantom Opera",
    chain: "FTM",
    network: "mainnet",
    rpcUrls: ["https://rpc.ftm.tools"],
    faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
    },
    infoURL: "https://fantom.foundation",
    shortName: "ftm",
    chainId: 250,
    networkId: 250,
    icon: "fantom",
    explorers: [
      {
        name: "ftmscan",
        url: "https://ftmscan.com",
        icon: "ftmscan",
        standard: "EIP3091",
      },
    ],
  },

  {
    name: "Moonbeam",
    chain: "MOON",
    network: "moonbeam",
    rpcUrls: [],
    faucets: [],
    nativeCurrency: {
      name: "Glimmer",
      symbol: "GLMR",
      decimals: 18,
    },
    infoURL: "https://moonbeam.network/networks/moonbeam/",
    shortName: "mbeam",
    chainId: 1284,
    networkId: 1284,
  },

  {
    name: "Fantom Testnet",
    chain: "FTM",
    network: "testnet",
    rpcUrls: ["https://rpc.testnet.fantom.network"],
    faucets: ["https://faucet.fantom.network"],
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
    },
    infoURL:
      "https://docs.fantom.foundation/quick-start/short-guide#fantom-testnet",
    shortName: "tftm",
    chainId: 4002,
    networkId: 4002,
    icon: "fantom",
    explorers: [
      {
        name: "ftmscan",
        url: "https://testnet.ftmscan.com/",
        icon: "ftmscan",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "IoTeX Network Mainnet",
    chain: "iotex.io",
    network: "mainnet",
    rpcUrls: ["https://babel-api.mainnet.iotex.io"],
    faucets: [],
    nativeCurrency: {
      name: "IoTeX",
      symbol: "IOTX",
      decimals: 18,
    },
    infoURL: "https://iotex.io",
    shortName: "iotex-mainnet",
    chainId: 4689,
    networkId: 4689,
    explorers: [
      {
        name: "iotexscan",
        url: "https://iotexscan.io",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "IoTeX Network Testnet",
    chain: "iotex.io",
    network: "testnet",
    rpcUrls: ["https://babel-api.testnet.iotex.io"],
    faucets: ["https://faucet.iotex.io/"],
    nativeCurrency: {
      name: "IoTeX",
      symbol: "IOTX",
      decimals: 18,
    },
    infoURL: "https://iotex.io",
    shortName: "iotex-testnet",
    chainId: 4690,
    networkId: 4690,
    explorers: [
      {
        name: "testnet iotexscan",
        url: "https://testnet.iotexscan.io",
        standard: "EIP3091",
      },
    ],
  },

  {
    name: "Celo Mainnet",
    chainId: 42220,
    shortName: "CELO",
    chain: "CELO",
    network: "Mainnet",
    networkId: 42220,
    nativeCurrency: {
      name: "CELO",
      symbol: "CELO",
      decimals: 18,
    },
    rpcUrls: ["https://forno.celo.org", "wss://forno.celo.org/ws"],
    faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
    infoURL: "https://docs.celo.org/",
    explorers: [
      {
        name: "blockscout",
        url: "https://explorer.celo.org",
        standard: "none",
      },
    ],
  },

  {
    name: "Athereum",
    chain: "ATH",
    network: "athereum",
    rpcUrls: ["https://ava.network:21015/ext/evm/rpc"],
    faucets: ["http://athfaucet.ava.network//?address=${ADDRESS}"],
    nativeCurrency: {
      name: "Athereum Ether",
      symbol: "ATH",
      decimals: 18,
    },
    infoURL: "https://athereum.ava.network",
    shortName: "avaeth",
    chainId: 43110,
    networkId: 43110,
  },
  {
    name: "Avalanche Fuji Testnet",
    chain: "AVAX",
    network: "testnet",
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    faucets: ["https://faucet.avax-test.network/"],
    nativeCurrency: {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
    },
    infoURL: "https://cchain.explorer.avax-test.network",
    shortName: "Fuji",
    chainId: 43113,
    networkId: 1,
    explorers: [
      {
        name: "snowtrace",
        url: "https://testnet.snowtrace.io/",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Avalanche Mainnet",
    chain: "AVAX",
    network: "mainnet",
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
    nativeCurrency: {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
    },
    infoURL: "https://www.avax.network/",
    shortName: "Avalanche",
    chainId: 43114,
    networkId: 43114,
    slip44: 9000,
    explorers: [
      {
        name: "snowtrace",
        url: "https://snowtrace.io/",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Celo Alfajores Testnet",
    chainId: 44787,
    shortName: "ALFA",
    chain: "CELO",
    network: "Alfajores",
    networkId: 44787,
    nativeCurrency: {
      name: "CELO",
      symbol: "CELO",
      decimals: 18,
    },
    rpcUrls: [
      "https://alfajores-forno.celo-testnet.org",
      "wss://alfajores-forno.celo-testnet.org/ws",
    ],
    faucets: [
      "https://celo.org/developers/faucet",
      "https://cauldron.pretoriaresearchlab.io/alfajores-faucet",
    ],
    infoURL: "https://docs.celo.org/",
  },
  {
    name: "Energi Testnet",
    chain: "NRG",
    network: "testnet",
    rpcUrls: ["https://nodeapi.test.energi.network"],
    faucets: [],
    nativeCurrency: {
      name: "Energi",
      symbol: "NRG",
      decimals: 18,
    },
    infoURL: "https://www.energi.world/",
    shortName: "tnrg",
    chainId: 49797,
    networkId: 49797,
    slip44: 49797,
  },
  {
    name: "Thinkium Testnet Chain 0",
    chain: "Thinkium",
    network: "testnet",
    rpcUrls: ["https://test.thinkiumrpc.net/"],
    faucets: ["https://www.thinkiumdev.net/faucet"],
    nativeCurrency: {
      name: "TKM",
      symbol: "TKM",
      decimals: 18,
    },
    infoURL: "https://thinkium.net/",
    shortName: "TKM-test0",
    chainId: 60000,
    networkId: 60000,
    explorers: [
      {
        name: "thinkiumscan",
        url: "https://test0.thinkiumscan.net/",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Thinkium Testnet Chain 1",
    chain: "Thinkium",
    network: "testnet",
    rpcUrls: ["https://test1.thinkiumrpc.net/"],
    faucets: ["https://www.thinkiumdev.net/faucet"],
    nativeCurrency: {
      name: "TKM",
      symbol: "TKM",
      decimals: 18,
    },
    infoURL: "https://thinkium.net/",
    shortName: "TKM-test1",
    chainId: 60001,
    networkId: 60001,
    explorers: [
      {
        name: "thinkiumscan",
        url: "https://test1.thinkiumscan.net/",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Thinkium Testnet Chain 2",
    chain: "Thinkium",
    network: "testnet",
    rpcUrls: ["https://test2.thinkiumrpc.net/"],
    faucets: ["https://www.thinkiumdev.net/faucet"],
    nativeCurrency: {
      name: "TKM",
      symbol: "TKM",
      decimals: 18,
    },
    infoURL: "https://thinkium.net/",
    shortName: "TKM-test2",
    chainId: 60002,
    networkId: 60002,
    explorers: [
      {
        name: "thinkiumscan",
        url: "https://test2.thinkiumscan.net/",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Thinkium Testnet Chain 103",
    chain: "Thinkium",
    network: "testnet",
    rpcUrls: ["https://test103.thinkiumrpc.net/"],
    faucets: ["https://www.thinkiumdev.net/faucet"],
    nativeCurrency: {
      name: "TKM",
      symbol: "TKM",
      decimals: 18,
    },
    infoURL: "https://thinkium.net/",
    shortName: "TKM-test103",
    chainId: 60103,
    networkId: 60103,
    explorers: [
      {
        name: "thinkiumscan",
        url: "https://test103.thinkiumscan.net",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Celo Baklava Testnet",
    chainId: 62320,
    shortName: "BKLV",
    chain: "CELO",
    network: "Baklava",
    networkId: 62320,
    nativeCurrency: {
      name: "CELO",
      symbol: "CELO",
      decimals: 18,
    },
    rpcUrls: ["https://baklava-forno.celo-testnet.org"],
    faucets: [
      "https://docs.google.com/forms/d/e/1FAIpQLSdfr1BwUTYepVmmvfVUDRCwALejZ-TUva2YujNpvrEmPAX2pg/viewform",
      "https://cauldron.pretoriaresearchlab.io/baklava-faucet",
    ],
    infoURL: "https://docs.celo.org/",
  },
  {
    name: "Thinkium Mainnet Chain 0",
    chain: "Thinkium",
    network: "mainnet",
    rpcUrls: ["https://proxy.thinkiumrpc.net/"],
    faucets: [],
    nativeCurrency: {
      name: "TKM",
      symbol: "TKM",
      decimals: 18,
    },
    infoURL: "https://thinkium.net/",
    shortName: "TKM0",
    chainId: 70000,
    networkId: 70000,
    explorers: [
      {
        name: "thinkiumscan",
        url: "https://chain0.thinkiumscan.net/",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Thinkium Mainnet Chain 1",
    chain: "Thinkium",
    network: "mainnet",
    rpcUrls: ["https://proxy1.thinkiumrpc.net/"],
    faucets: [],
    nativeCurrency: {
      name: "TKM",
      symbol: "TKM",
      decimals: 18,
    },
    infoURL: "https://thinkium.net/",
    shortName: "TKM1",
    chainId: 70001,
    networkId: 70001,
    explorers: [
      {
        name: "thinkiumscan",
        url: "https://chain1.thinkiumscan.net/",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Thinkium Mainnet Chain 2",
    chain: "Thinkium",
    network: "mainnet",
    rpcUrls: ["https://proxy2.thinkiumrpc.net/"],
    faucets: [],
    nativeCurrency: {
      name: "TKM",
      symbol: "TKM",
      decimals: 18,
    },
    infoURL: "https://thinkium.net/",
    shortName: "TKM2",
    chainId: 70002,
    networkId: 70002,
    explorers: [
      {
        name: "thinkiumscan",
        url: "https://chain2.thinkiumscan.net/",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Thinkium Mainnet Chain 103",
    chain: "Thinkium",
    network: "mainnet",
    rpcUrls: ["https://proxy103.thinkiumrpc.net/"],
    faucets: [],
    nativeCurrency: {
      name: "TKM",
      symbol: "TKM",
      decimals: 18,
    },
    infoURL: "https://thinkium.net/",
    shortName: "TKM103",
    chainId: 70103,
    networkId: 70103,
    explorers: [
      {
        name: "thinkiumscan",
        url: "https://chain103.thinkiumscan.net/",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Godwoken Testnet",
    chain: "CKB",
    tnChainId: 71401,
    network: "testnet",
    rpcUrls: ["https://godwoken-testnet-v1.ckbapp.dev"],
    faucets: ["https://faucet.nervos.org/"],
    nativeCurrency: {
      name: "CKB",
      symbol: "CKB",
      decimals: 8,
    },
    infoURL: "https://github.com/nervosnetwork/godwoken",
    shortName: "ckb",
  },
  {
    name: "Godwoken Mainnet",
    chain: "CKB",
    network: "mainnet",
    icon: "godwoken",
    rpcUrls: ["https://v1.mainnet.godwoken.io/rpc"],
    faucets: ["https://faucet.nervos.org/"],
    nativeCurrency: {
      name: "CKB",
      symbol: "CKB",
      decimals: 8,
    },
    infoURL: "https://github.com/nervosnetwork/godwoken",
    shortName: "ckb",
    chainId: 71402,
    networkId: 1,
  },
  {
    name: "Energy Web Volta Testnet",
    chain: "Volta",
    network: "testnet",
    rpcUrls: [
      "https://volta-rpc.energyweb.org",
      "wss://volta-rpc.energyweb.org/ws",
    ],
    faucets: ["https://voltafaucet.energyweb.org"],
    nativeCurrency: {
      name: "Volta Token",
      symbol: "VT",
      decimals: 18,
    },
    infoURL: "https://energyweb.org",
    shortName: "vt",
    chainId: 73799,
    networkId: 73799,
  },
  {
    name: "Firenze test network",
    chain: "ETH",
    network: "testnet",
    rpcUrls: ["https://ethnode.primusmoney.com/firenze"],
    faucets: [],
    nativeCurrency: {
      name: "Firenze Ether",
      symbol: "FIN",
      decimals: 18,
    },
    infoURL: "https://primusmoney.com",
    shortName: "firenze",
    chainId: 78110,
    networkId: 78110,
  },
  {
    //name: "Polygon Testnet Mumbai",
    chainName: "Polygon",
    //network: "testnet",
    rpcUrls: [
      "https://matic-mumbai.chainstacklabs.com",
      "https://rpc-mumbai.maticvigil.com",
      "https://matic-testnet-archive-rpc.bwarelabs.com",
    ],
    //faucets: ["https://faucet.polygon.technology/"],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    //infoURL: "https://polygon.technology/",
    //shortName: "maticmum",
    chainId: 80001,
    //networkId: 80001,
    /*explorers: [
      {
        name: "polygonscan",
        url: "https://mumbai.polygonscan.com/",
        standard: "EIP3091",
      },
    ],*/
  },
  {
    name: "QuarkChain Mainnet Root",
    chain: "QuarkChain",
    network: "mainnet",
    rpcUrls: ["http://jrpc.mainnet.quarkchain.io:38391/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-r",
    chainId: 100000,
    networkId: 100000,
  },
  {
    name: "QuarkChain Mainnet Shard 0",
    chain: "QuarkChain",
    network: "mainnet",
    rpcUrls: ["http://eth-jrpc.mainnet.quarkchain.io:39000/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-s0",
    chainId: 100001,
    networkId: 100001,
    parent: {
      chain: "eip155-100000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-mainnet",
        url: "https://mainnet.quarkchain.io/0",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Mainnet Shard 1",
    chain: "QuarkChain",
    network: "mainnet",
    rpcUrls: ["http://eth-jrpc.mainnet.quarkchain.io:39001/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-s1",
    chainId: 100002,
    networkId: 100002,
    parent: {
      chain: "eip155-100000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-mainnet",
        url: "https://mainnet.quarkchain.io/1",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Mainnet Shard 2",
    chain: "QuarkChain",
    network: "mainnet",
    rpcUrls: ["http://eth-jrpc.mainnet.quarkchain.io:39002/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-s2",
    chainId: 100003,
    networkId: 100003,
    parent: {
      chain: "eip155-100000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-mainnet",
        url: "https://mainnet.quarkchain.io/2",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Mainnet Shard 3",
    chain: "QuarkChain",
    network: "mainnet",
    rpcUrls: ["http://eth-jrpc.mainnet.quarkchain.io:39003/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-s3",
    chainId: 100004,
    networkId: 100004,
    parent: {
      chain: "eip155-100000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-mainnet",
        url: "https://mainnet.quarkchain.io/3",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Mainnet Shard 4",
    chain: "QuarkChain",
    network: "mainnet",
    rpcUrls: ["http://eth-jrpc.mainnet.quarkchain.io:39004/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-s4",
    chainId: 100005,
    networkId: 100005,
    parent: {
      chain: "eip155-100000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-mainnet",
        url: "https://mainnet.quarkchain.io/4",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Mainnet Shard 5",
    chain: "QuarkChain",
    network: "mainnet",
    rpcUrls: ["http://eth-jrpc.mainnet.quarkchain.io:39005/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-s5",
    chainId: 100006,
    networkId: 100006,
    parent: {
      chain: "eip155-100000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-mainnet",
        url: "https://mainnet.quarkchain.io/5",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Mainnet Shard 6",
    chain: "QuarkChain",
    network: "mainnet",
    rpcUrls: ["http://eth-jrpc.mainnet.quarkchain.io:39006/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-s6",
    chainId: 100007,
    networkId: 100007,
    parent: {
      chain: "eip155-100000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-mainnet",
        url: "https://mainnet.quarkchain.io/6",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Mainnet Shard 7",
    chain: "QuarkChain",
    network: "mainnet",
    rpcUrls: ["http://eth-jrpc.mainnet.quarkchain.io:39007/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-s7",
    chainId: 100008,
    networkId: 100008,
    parent: {
      chain: "eip155-100000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-mainnet",
        url: "https://mainnet.quarkchain.io/7",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Devnet Root",
    chain: "QuarkChain",
    network: "devnet",
    rpcUrls: ["http://jrpc.devnet.quarkchain.io:38391/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-d-r",
    chainId: 110000,
    networkId: 110000,
  },
  {
    name: "QuarkChain Devnet Shard 0",
    chain: "QuarkChain",
    network: "devnet",
    rpcUrls: ["http://eth-jrpc.devnet.quarkchain.io:39900/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-d-s0",
    chainId: 110001,
    networkId: 110001,
    parent: {
      chain: "eip155-110000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-devnet",
        url: "https://devnet.quarkchain.io/0",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Devnet Shard 1",
    chain: "QuarkChain",
    network: "devnet",
    rpcUrls: ["http://eth-jrpc.devnet.quarkchain.io:39901/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-d-s1",
    chainId: 110002,
    networkId: 110002,
    parent: {
      chain: "eip155-110000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-devnet",
        url: "https://devnet.quarkchain.io/1",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Devnet Shard 2",
    chain: "QuarkChain",
    network: "devnet",
    rpcUrls: ["http://eth-jrpc.devnet.quarkchain.io:39902/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-d-s2",
    chainId: 110003,
    networkId: 110003,
    parent: {
      chain: "eip155-110000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-devnet",
        url: "https://devnet.quarkchain.io/2",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Devnet Shard 3",
    chain: "QuarkChain",
    network: "devnet",
    rpcUrls: ["http://eth-jrpc.devnet.quarkchain.io:39903/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-d-s3",
    chainId: 110004,
    networkId: 110004,
    parent: {
      chain: "eip155-110000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-devnet",
        url: "https://devnet.quarkchain.io/3",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Devnet Shard 4",
    chain: "QuarkChain",
    network: "devnet",
    rpcUrls: ["http://eth-jrpc.devnet.quarkchain.io:39904/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-d-s4",
    chainId: 110005,
    networkId: 110005,
    parent: {
      chain: "eip155-110000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-devnet",
        url: "https://devnet.quarkchain.io/4",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Devnet Shard 5",
    chain: "QuarkChain",
    network: "devnet",
    rpcUrls: ["http://eth-jrpc.devnet.quarkchain.io:39905/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-d-s5",
    chainId: 110006,
    networkId: 110006,
    parent: {
      chain: "eip155-110000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-devnet",
        url: "https://devnet.quarkchain.io/5",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Devnet Shard 6",
    chain: "QuarkChain",
    network: "devnet",
    rpcUrls: ["http://eth-jrpc.devnet.quarkchain.io:39906/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-d-s6",
    chainId: 110007,
    networkId: 110007,
    parent: {
      chain: "eip155-110000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-devnet",
        url: "https://devnet.quarkchain.io/6",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "QuarkChain Devnet Shard 7",
    chain: "QuarkChain",
    network: "devnet",
    rpcUrls: ["http://eth-jrpc.devnet.quarkchain.io:39907/"],
    faucets: [],
    nativeCurrency: {
      name: "QKC",
      symbol: "QKC",
      decimals: 18,
    },
    infoURL: "https://www.quarkchain.io/",
    shortName: "qkc-d-s7",
    chainId: 110008,
    networkId: 110008,
    parent: {
      chain: "eip155-110000",
      type: "shard",
    },
    explorers: [
      {
        name: "quarkchain-devnet",
        url: "https://devnet.quarkchain.io/7",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Akroma",
    chain: "AKA",
    network: "mainnet",
    rpcUrls: ["https://remote.akroma.io"],
    faucets: [],
    nativeCurrency: {
      name: "Akroma Ether",
      symbol: "AKA",
      decimals: 18,
    },
    infoURL: "https://akroma.io",
    shortName: "aka",
    chainId: 200625,
    networkId: 200625,
    slip44: 200625,
  },
  {
    name: "Alaya Dev Testnet",
    chain: "Alaya",
    network: "testnet",
    rpcUrls: [
      "https://devnetopenapi.alaya.network/rpc",
      "wss://devnetopenapi.alaya.network/ws",
    ],
    faucets: [
      "https://faucet.alaya.network/faucet/?id=f93426c0887f11eb83b900163e06151c",
    ],
    nativeCurrency: {
      name: "ATP",
      symbol: "atp",
      decimals: 18,
    },
    infoURL: "https://www.alaya.network/",
    shortName: "alaya",
    chainId: 201030,
    networkId: 1,
    icon: "alaya",
    explorers: [
      {
        name: "alaya explorer",
        url: "https://devnetscan.alaya.network",
        standard: "none",
      },
    ],
  },
  {
    name: "PlatON Dev Testnet",
    chain: "PlatON",
    network: "testnet",
    rpcUrls: [
      "https://devnetopenapi.platon.network/rpc",
      "wss://devnetopenapi.platon.network/ws",
    ],
    faucets: [
      "https://faucet.platon.network/faucet/?id=e5d32df10aee11ec911142010a667c03",
    ],
    nativeCurrency: {
      name: "LAT",
      symbol: "lat",
      decimals: 18,
    },
    infoURL: "https://www.platon.network",
    shortName: "PlatON",
    chainId: 210309,
    networkId: 1,
    icon: "platon",
    explorers: [
      {
        name: "PlatON explorer",
        url: "https://devnetscan.platon.network/",
        standard: "none",
      },
    ],
  },
  {
    name: "ARTIS sigma1",
    chain: "ARTIS",
    network: "sigma1",
    rpcUrls: ["https://rpc.sigma1.artis.network"],
    faucets: [],
    nativeCurrency: {
      name: "ARTIS sigma1 Ether",
      symbol: "ATS",
      decimals: 18,
    },
    infoURL: "https://artis.eco",
    shortName: "ats",
    chainId: 246529,
    networkId: 246529,
    slip44: 246529,
  },
  {
    name: "ARTIS Testnet tau1",
    chain: "ARTIS",
    network: "tau1",
    rpcUrls: ["https://rpc.tau1.artis.network"],
    faucets: [],
    nativeCurrency: {
      name: "ARTIS tau1 Ether",
      symbol: "tATS",
      decimals: 18,
    },
    infoURL: "https://artis.network",
    shortName: "atstau",
    chainId: 246785,
    networkId: 246785,
  },
  {
    name: "Polis Testnet",
    chain: "Sparta",
    network: "testnet",
    icon: "polis",
    rpcUrls: ["https://sparta-rpc.polis.tech"],
    faucets: ["https://faucet.polis.tech"],
    nativeCurrency: {
      name: "tPolis",
      symbol: "tPOLIS",
      decimals: 18,
    },
    infoURL: "https://polis.tech",
    shortName: "sparta",
    chainId: 333888,
    networkId: 333888,
  },
  {
    name: "Polis Mainnet",
    chain: "Olympus",
    network: "mainnet",
    icon: "polis",
    rpcUrls: ["https://rpc.polis.tech"],
    faucets: ["https://faucet.polis.tech"],
    nativeCurrency: {
      name: "Polis",
      symbol: "POLIS",
      decimals: 18,
    },
    infoURL: "https://polis.tech",
    shortName: "olympus",
    chainId: 333999,
    networkId: 333999,
  },
  {
    name: "Arbitrum Testnet Rinkeby",
    chainId: 421611,
    shortName: "arb-rinkeby",
    chain: "ETH",
    network: "rinkeby",
    networkId: 421611,
    nativeCurrency: {
      name: "Arbitrum Rinkeby Ether",
      symbol: "ARETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://rinkeby.arbitrum.io/rpc",
      "wss://rinkeby.arbitrum.io/ws",
    ],
    faucets: [],
    infoURL: "https://arbitrum.io",
    explorers: [
      {
        name: "arbitrum-rinkeby",
        url: "https://rinkeby-explorer.arbitrum.io",
        standard: "EIP3091",
      },
    ],
    parent: {
      type: "L2",
      chain: "eip155-4",
      bridges: [
        {
          url: "https://bridge.arbitrum.io",
        },
      ],
    },
  },
  {
    name: "Eluvio Content Fabric",
    chain: "Eluvio",
    network: "mainnet",
    rpcUrls: [
      "https://host-76-74-28-226.contentfabric.io/eth/",
      "https://host-76-74-28-232.contentfabric.io/eth/",
      "https://host-76-74-29-2.contentfabric.io/eth/",
      "https://host-76-74-29-8.contentfabric.io/eth/",
      "https://host-76-74-29-34.contentfabric.io/eth/",
      "https://host-76-74-29-35.contentfabric.io/eth/",
      "https://host-154-14-211-98.contentfabric.io/eth/",
      "https://host-154-14-192-66.contentfabric.io/eth/",
      "https://host-60-240-133-202.contentfabric.io/eth/",
      "https://host-64-235-250-98.contentfabric.io/eth/",
    ],
    faucets: [],
    nativeCurrency: {
      name: "ELV",
      symbol: "ELV",
      decimals: 18,
    },
    infoURL: "https://eluv.io",
    shortName: "elv",
    chainId: 955305,
    networkId: 955305,
    slip44: 1011,
    explorers: [
      {
        name: "blockscout",
        url: "https://explorer.eluv.io",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Etho Protocol",
    chain: "ETHO",
    network: "mainnet",
    rpcUrls: ["https://rpc.ether1.org"],
    faucets: [],
    nativeCurrency: {
      name: "Etho Protocol",
      symbol: "ETHO",
      decimals: 18,
    },
    infoURL: "https://ethoprotocol.com",
    shortName: "etho",
    chainId: 1313114,
    networkId: 1313114,
    slip44: 1313114,
    explorers: [
      {
        name: "blockscout",
        url: "https://explorer.ethoprotocol.com",
        standard: "none",
      },
    ],
  },
  {
    name: "Xerom",
    chain: "XERO",
    network: "mainnet",
    rpcUrls: ["https://rpc.xerom.org"],
    faucets: [],
    nativeCurrency: {
      name: "Xerom Ether",
      symbol: "XERO",
      decimals: 18,
    },
    infoURL: "https://xerom.org",
    shortName: "xero",
    chainId: 1313500,
    networkId: 1313500,
  },
  {
    name: "Musicoin",
    chain: "MUSIC",
    network: "mainnet",
    rpcUrls: ["https://mewapi.musicoin.tw"],
    faucets: [],
    nativeCurrency: {
      name: "Musicoin",
      symbol: "MUSIC",
      decimals: 18,
    },
    infoURL: "https://musicoin.tw",
    shortName: "music",
    chainId: 7762959,
    networkId: 7762959,
    slip44: 184,
  },
  {
    name: "PepChain Churchill",
    chain: "PEP",
    network: "testnet",
    rpcUrls: ["https://churchill-rpc.pepchain.io"],
    faucets: [],
    nativeCurrency: {
      name: "PepChain Churchill Ether",
      symbol: "TPEP",
      decimals: 18,
    },
    infoURL: "https://pepchain.io",
    shortName: "tpep",
    chainId: 13371337,
    networkId: 13371337,
  },
  {
    name: "IOLite",
    chain: "ILT",
    network: "mainnet",
    rpcUrls: ["https://net.iolite.io"],
    faucets: [],
    nativeCurrency: {
      name: "IOLite Ether",
      symbol: "ILT",
      decimals: 18,
    },
    infoURL: "https://iolite.io",
    shortName: "ilt",
    chainId: 18289463,
    networkId: 18289463,
  },
  {
    name: "quarkblockchain",
    chain: "QKI",
    network: "mainnet",
    rpcUrls: ["https://hz.rpc.qkiscan.cn", "https://jp.rpc.qkiscan.io"],
    faucets: [],
    nativeCurrency: {
      name: "quarkblockchain Native Token",
      symbol: "QKI",
      decimals: 18,
    },
    infoURL: "https://quarkblockchain.org/",
    shortName: "qki",
    chainId: 20181205,
    networkId: 20181205,
  },
  {
    name: "Auxilium Network Mainnet",
    chain: "AUX",
    network: "mainnet",
    rpcUrls: ["https://rpc.auxilium.global"],
    faucets: [],
    nativeCurrency: {
      name: "Auxilium coin",
      symbol: "AUX",
      decimals: 18,
    },
    infoURL: "https://auxilium.global",
    shortName: "auxi",
    chainId: 28945486,
    networkId: 28945486,
    slip44: 344,
  },
  {
    name: "Joys Digital Mainnet",
    chain: "JOYS",
    network: "mainnet",
    rpcUrls: ["https://node.joys.digital"],
    faucets: [],
    nativeCurrency: {
      name: "JOYS",
      symbol: "JOYS",
      decimals: 18,
    },
    infoURL: "https://joys.digital",
    shortName: "JOYS",
    chainId: 35855456,
    networkId: 35855456,
  },
  {
    name: "Aquachain",
    chain: "AQUA",
    network: "mainnet",
    rpcUrls: ["https://c.onical.org", "https://tx.aquacha.in/api"],
    faucets: ["https://aquacha.in/faucet"],
    nativeCurrency: {
      name: "Aquachain Ether",
      symbol: "AQUA",
      decimals: 18,
    },
    infoURL: "https://aquachain.github.io",
    shortName: "aqua",
    chainId: 61717561,
    networkId: 61717561,
    slip44: 61717561,
  },
  {
    name: "Joys Digital TestNet",
    chain: "TOYS",
    network: "testnet",
    rpcUrls: ["https://toys.joys.cash/"],
    faucets: ["https://faucet.joys.digital/"],
    nativeCurrency: {
      name: "TOYS",
      symbol: "TOYS",
      decimals: 18,
    },
    infoURL: "https://joys.digital",
    shortName: "TOYS",
    chainId: 99415706,
    networkId: 99415706,
  },
  {
    name: "Neon EVM DevNet",
    chain: "Solana",
    network: "devnet",
    rpcUrls: ["https://proxy.devnet.neonlabs.org/solana"],
    faucets: ["https://neonswap.live/#/get-tokens"],
    nativeCurrency: {
      name: "Neon",
      symbol: "NEON",
      decimals: 18,
    },
    infoURL: "https://neon-labs.org/",
    shortName: "neonevm-devnet",
    chainId: 245022926,
    networkId: 245022926,
  },
  {
    name: "Neon EVM MainNet",
    chain: "Solana",
    network: "mainnet",
    rpcUrls: ["https://proxy.mainnet.neonlabs.org/solana"],
    faucets: [],
    nativeCurrency: {
      name: "Neon",
      symbol: "NEON",
      decimals: 18,
    },
    infoURL: "https://neon-labs.org/",
    shortName: "neonevm-mainnet",
    chainId: 245022934,
    networkId: 245022934,
  },
  {
    name: "Neon EVM TestNet",
    chain: "Solana",
    network: "testnet",
    rpcUrls: ["https://proxy.testnet.neonlabs.org/solana"],
    faucets: [],
    nativeCurrency: {
      name: "Neon",
      symbol: "NEON",
      decimals: 18,
    },
    infoURL: "https://neon-labs.org/",
    shortName: "neonevm-testnet",
    chainId: 245022940,
    networkId: 245022940,
  },
  {
    name: "OneLedger Mainnet",
    chain: "OLT",
    network: "mainnet",
    icon: "oneledger",
    rpcUrls: ["https://mainnet-rpc.oneledger.network"],
    faucets: [],
    nativeCurrency: {
      name: "OLT",
      symbol: "OLT",
      decimals: 18,
    },
    infoURL: "https://oneledger.io",
    shortName: "oneledger",
    chainId: 311752642,
    networkId: 311752642,
    explorers: [
      {
        name: "OneLedger Block Explorer",
        url: "https://mainnet-explorer.oneledger.network",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "IPOS Network",
    chain: "IPOS",
    network: "mainnet",
    rpcUrls: ["https://rpc.iposlab.com", "https://rpc2.iposlab.com"],
    faucets: [],
    nativeCurrency: {
      name: "IPOS Network Ether",
      symbol: "IPOS",
      decimals: 18,
    },
    infoURL: "https://iposlab.com",
    shortName: "ipos",
    chainId: 1122334455,
    networkId: 1122334455,
  },
  {
    chainName: "Skale Mainnet",
    rpcUrls: ["https://mainnet.skalenodes.com/v1/honorable-steel-rasalhague"],
    nativeCurrency: {
      name: "SKALE SKL",
      symbol: "SKL",
      decimals: 18,
    },
    blockExplorerUrls: [
      "https://expedition.dev/?rpcUrl=https://mainnet.skalenodes.com/v1/honorable-steel-rasalhague",
    ],
    chainId: 1564830818,
  },
  {
    chainId: 1305754875840118,
    chainName: "Skale Testnet",
    rpcUrls: ["https://staging-v2.skalenodes.com/v1/rapping-zuben-elakrab"],
    nativeCurrency: {
      name: "SFUEL",
      symbol: "SFUEL",
      decimals: 18,
    },
  },
  {
    name: "Aurora MainNet",
    chain: "NEAR",
    network: "mainnet",
    rpcUrls: ["https://mainnet.aurora.dev"],
    faucets: [],
    nativeCurrency: {
      name: "Ether",
      symbol: "aETH",
      decimals: 18,
    },
    infoURL: "https://aurora.dev",
    shortName: "aurora",
    chainId: 1313161554,
    networkId: 1313161554,
  },
  {
    name: "Aurora TestNet",
    chain: "NEAR",
    network: "testnet",
    rpcUrls: ["https://testnet.aurora.dev/"],
    faucets: [],
    nativeCurrency: {
      name: "Ether",
      symbol: "aETH",
      decimals: 18,
    },
    infoURL: "https://aurora.dev",
    shortName: "aurora-testnet",
    tnChainId: 1313161555,
    networkId: 1313161555,
  },
  {
    name: "Aurora BetaNet",
    chain: "NEAR",
    network: "betanet",
    rpcUrls: ["https://betanet.aurora.dev/"],
    faucets: [],
    nativeCurrency: {
      name: "Ether",
      symbol: "aETH",
      decimals: 18,
    },
    infoURL: "https://aurora.dev",
    shortName: "aurora-betanet",
    chainId: 1313161556,
    networkId: 1313161556,
  },
  {
    name: "Harmony Mainnet Shard 0",
    chain: "Harmony",
    network: "mainnet",
    rpcUrls: ["https://api.harmony.one"],
    faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
    nativeCurrency: {
      name: "ONE",
      symbol: "ONE",
      decimals: 18,
    },
    infoURL: "https://www.harmony.one/",
    shortName: "hmy-s0",
    chainId: 1666600000,
    networkId: 1666600000,
    explorers: [
      {
        name: "Harmony Block Explorer",
        url: "https://explorer.harmony.one",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Harmony Mainnet Shard 1",
    chain: "Harmony",
    network: "mainnet",
    rpcUrls: ["https://s1.api.harmony.one"],
    faucets: [],
    nativeCurrency: {
      name: "ONE",
      symbol: "ONE",
      decimals: 18,
    },
    infoURL: "https://www.harmony.one/",
    shortName: "hmy-s1",
    chainId: 1666600001,
    networkId: 1666600001,
  },
  {
    name: "Harmony Mainnet Shard 2",
    chain: "Harmony",
    network: "mainnet",
    rpcUrls: ["https://s2.api.harmony.one"],
    faucets: [],
    nativeCurrency: {
      name: "ONE",
      symbol: "ONE",
      decimals: 18,
    },
    infoURL: "https://www.harmony.one/",
    shortName: "hmy-s2",
    chainId: 1666600002,
    networkId: 1666600002,
  },
  {
    name: "Harmony Mainnet Shard 3",
    chain: "Harmony",
    network: "mainnet",
    rpcUrls: ["https://s3.api.harmony.one"],
    faucets: [],
    nativeCurrency: {
      name: "ONE",
      symbol: "ONE",
      decimals: 18,
    },
    infoURL: "https://www.harmony.one/",
    shortName: "hmy-s3",
    chainId: 1666600003,
    networkId: 1666600003,
  },
  {
    name: "Harmony Testnet Shard 0",
    chain: "Harmony",
    network: "testnet",
    rpcUrls: ["https://api.s0.b.hmny.io"],
    faucets: ["https://faucet.pops.one"],
    nativeCurrency: {
      name: "ONE",
      symbol: "ONE",
      decimals: 18,
    },
    infoURL: "https://www.harmony.one/",
    shortName: "hmy-b-s0",
    chainId: 1666700000,
    networkId: 1666700000,
    explorers: [
      {
        name: "Harmony Testnet Block Explorer",
        url: "https://explorer.pops.one",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Harmony Testnet Shard 1",
    chain: "Harmony",
    network: "testnet",
    rpcUrls: ["https://api.s1.b.hmny.io"],
    faucets: [],
    nativeCurrency: {
      name: "ONE",
      symbol: "ONE",
      decimals: 18,
    },
    infoURL: "https://www.harmony.one/",
    shortName: "hmy-b-s1",
    chainId: 1666700001,
    networkId: 1666700001,
  },
  {
    name: "Harmony Testnet Shard 2",
    chain: "Harmony",
    network: "testnet",
    rpcUrls: ["https://api.s2.b.hmny.io"],
    faucets: [],
    nativeCurrency: {
      name: "ONE",
      symbol: "ONE",
      decimals: 18,
    },
    infoURL: "https://www.harmony.one/",
    shortName: "hmy-b-s2",
    chainId: 1666700002,
    networkId: 1666700002,
  },
  {
    name: "Harmony Testnet Shard 3",
    chain: "Harmony",
    network: "testnet",
    rpcUrls: ["https://api.s3.b.hmny.io"],
    faucets: [],
    nativeCurrency: {
      name: "ONE",
      symbol: "ONE",
      decimals: 18,
    },
    infoURL: "https://www.harmony.one/",
    shortName: "hmy-b-s3",
    chainId: 1666700003,
    networkId: 1666700003,
  },
  {
    name: "Pirl",
    chain: "PIRL",
    network: "mainnet",
    rpcUrls: ["https://wallrpc.pirl.io"],
    faucets: [],
    nativeCurrency: {
      name: "Pirl Ether",
      symbol: "PIRL",
      decimals: 18,
    },
    infoURL: "https://pirl.io",
    shortName: "pirl",
    chainId: 3125659152,
    networkId: 3125659152,
    slip44: 164,
  },
  {
    name: "OneLedger Testnet Frankenstein",
    chain: "OLT",
    network: "testnet",
    icon: "oneledger",
    rpcUrls: ["https://frankenstein-rpc.oneledger.network"],
    faucets: ["https://frankenstein-faucet.oneledger.network"],
    nativeCurrency: {
      name: "OLT",
      symbol: "OLT",
      decimals: 18,
    },
    infoURL: "https://oneledger.io",
    shortName: "frankenstein",
    chainId: 4216137055,
    networkId: 4216137055,
    explorers: [
      {
        name: "OneLedger Block Explorer",
        url: "https://frankenstein-explorer.oneledger.network",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "Palm Testnet",
    chain: "Palm",
    network: "testnet",
    rpcUrls: [],
    faucets: [],
    nativeCurrency: {
      name: "PALM",
      symbol: "PALM",
      decimals: 18,
    },
    infoURL: "https://palm.io",
    shortName: "tpalm",
    chainId: 11297108099,
    networkId: 11297108099,
  },
  {
    name: "Palm Mainnet",
    chain: "Palm",
    network: "mainnet",
    rpcUrls: [],
    faucets: [],
    nativeCurrency: {
      name: "PALM",
      symbol: "PALM",
      decimals: 18,
    },
    infoURL: "https://palm.io",
    shortName: "palm",
    chainId: 11297108109,
    networkId: 11297108109,
  },
  {
    name: "VeChain Testnet",
    chain: "VeChain",
    network: "testnet",
    rpcUrls: ["https://sync-testnet.veblocks.net"],
    faucets: [],
    nativeCurrency: {
      name: "VeChain",
      symbol: "VET",
      decimals: 18,
    },
    infoURL: "",
    shortName: "vechain",
    chainId: 39,
    networkId: undefined,
    explorers: [
      {
        name: "VeChain",
        url: "https://explore-testnet.vechain.org/transactions/",
        standard: "EIP3091",
      },
    ],
  },
  {
    name: "VeChain Mainnet",
    chain: "VeChain",
    network: "mainnet",
    rpcUrls: ["https://sync-mainnet.veblocks.net"],
    faucets: [],
    nativeCurrency: {
      name: "VeChain",
      symbol: "VET",
      decimals: 18,
    },
    infoURL: "",
    shortName: "vechain",
    chainId: undefined,
    networkId: 0x4a,
    explorers: [
      {
        name: "VeChain",
        url: "https://explore.vechain.org/transactions/",
        standard: "EIP3091",
      },
    ],
  },
];
