import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import RedClose from "../../assets/img/icons/RedClose.svg";
import {
  setDepartureOrDestination,
  setReceiver,
  setPrivateKey,
  setSwitchDestination,
} from "../../store/reducers/generalSlice";
import ChainSwitch from "../Buttons/ChainSwitch";
// import vet from "../../assets/img/Vector.svg";
// import { ReactComponent as Vet } from "../../assets/img/Vector.svg";

function DestinationChain() {
  const alert = useSelector((state) => state.general.pasteDestinationAlert);
  const to = useSelector((state) => state.general.to);
  const { widget } = useSelector(({ general: { widget } }) => ({
    widget,
  }));
  const from = useSelector((state) => state.general.from.key);
  const dispatch = useDispatch();
  const receiver = useSelector((state) => state.general.receiver);
  const pk = useSelector((state) => state.general.privateKey);

  const [epk1, setEpk1] = useState("");
  const [epk2, setEpk2] = useState("");
  const [epk3, setEpk3] = useState("");
  const [epk4, setEpk4] = useState("");
  const [epk5, setEpk5] = useState("");

  const handleChange = (e) => {
    dispatch(setReceiver(e.target.value));
  };

  useEffect(()=>{
    handleElPkChange()
  },[epk1 ,epk2  ,epk3 ,epk4 ,epk5])

  const handlePkChange = (e) => {
      dispatch(setPrivateKey(e.target.value));
  };
  const handleElPkChange = (e) => {
      let elrondPrivateKey = epk1+`\n`+epk2+`\n`+epk3+`\n`+epk4+`\n`+epk5;
      dispatch(setPrivateKey(elrondPrivateKey));
      console.log(elrondPrivateKey)
      console.log(pk)
  };

  function handleSwitchChain() {
    dispatch(setDepartureOrDestination("destination"));
    dispatch(setSwitchDestination(true));
  }

  useEffect(() => {}, [to]);

  return (
    <div className="destination-props">
      <div className="destination__header">
        <span className="destination__title">Destination</span>
        <ChainSwitch assignment={"to"} func={handleSwitchChain} />
      </div>

      <div className={!alert ? "destination__address" : "destination__address desti-alert"}>
        <input
          value={receiver}
          onChange={(e) => handleChange(e)}
          type="text"
          placeholder="Paste destination address"
        />
        <div className="destination__header">
          <span className="destination__title">Private Key</span>
        </div>
        {from === "Elrond" ? (
          <div>
            <input
              value={epk1}
              onChange={(e) =>setEpk1(e.target.value)}
              type="text"
              placeholder="Paste Line 1"
            />
            <input
              className="elrondPkInput"
              value={epk2}
              onChange={(e) => setEpk2(e.target.value)}
              type="text"
              placeholder="Paste Line 2"
            />
            <input
              className="elrondPkInput"
              value={epk3}
              onChange={(e) => setEpk3(e.target.value)}
              type="text"
              placeholder="Paste Line 3"
            />

            <input
              className="elrondPkInput"
              value={epk4}
              onChange={(e) => setEpk4(e.target.value)}
              type="text"
              placeholder="Paste Line 4"
            />
            <input
              className="elrondPkInput"
              value={epk5}
              onChange={(e) => setEpk5(e.target.value)}
              type="text"
              placeholder="Paste Line 5"
            />
          </div>
        ) : (
          <input
            value={pk}
            onChange={(e) => handlePkChange(e)}
            type="text"
            placeholder="Paste Private Key"
          />
        )}

        <span className="invalid">
          <img src={RedClose} alt="Close" /> Invalid address
        </span>
      </div>
    </div>
  );
}

export default DestinationChain;
